import { configureStore } from "@reduxjs/toolkit";

import authReducer from "./authSlice";
import emailAccountReducer from "./emailAccountSlice";
import planReducer from "./planSlice";
import emailReducer from "./emailSlice";
const store = configureStore({
  reducer: {
    auth: authReducer,
    emailAccount: emailAccountReducer,
    plan: planReducer,
    emails: emailReducer,
  },
});
export default store;
