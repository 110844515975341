import * as React from "react";
import { v4 as uuidv } from "uuid";
import { Link } from "react-router-dom";

import { Copyright } from "@mui/icons-material";
import { Box, Grid, Divider, useTheme, Typography } from "@mui/material";
import Logo from "../../components/logo/logo";

export default function Footer() {
  const theme = useTheme();
  const pages = [
    { name: "Features", to: "/features" },
    { name: "Pricing", to: "/pricing" },
    { name: "About", to: "/about" },
    { name: "Blog", to: "/blog" },
  ];
  return (
    <Box
      component="footer"
      sx={{
        display: "block",
        py: 4,
        px: 4,
        mt: "auto",
        bottom: 0,
        left: 0,
        backgroundColor: theme.palette.background.dark,
      }}
    >
      <Box sx={{ marginY: 2, color: "#fff" }}>
        <Grid
          container
          spacing={{ xs: 2, md: 3 }}
          columns={{ xs: 4, sm: 8, md: 12 }}
          padding="2"
        >
          <Grid item xs={12} sm={6} gap={1}>
            <Logo />
          </Grid>
          <Grid item xs={6} sm={3} gap={1}>
            <Typography variant="h4" textTransform="uppercase">
              Quick Links
            </Typography>
            {pages.map((page) => (
              <Link key={uuidv()} to={page.to} sx={{ mx: 1 }}>
                <Typography
                  variant="body2"
                  sx={{ color: theme.palette.text.info, textTransform: "none" }}
                >
                  {page.name}
                </Typography>
              </Link>
            ))}
          </Grid>
          <Grid item xs={6} sm={3} gap={1}>
            <Typography variant="h4" textTransform="uppercase">
              Company
            </Typography>
            <Link to="/contact" sx={{ mx: 1 }}>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.info, textTransform: "none" }}
              >
                Contact us
              </Typography>
            </Link>
            <Link to="/terms" sx={{ mx: 1 }}>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.info, textTransform: "none" }}
              >
                Terms & Conditions
              </Typography>
            </Link>
            <Link to="/policy" sx={{ mx: 1 }}>
              <Typography
                variant="body2"
                sx={{ color: theme.palette.text.info, textTransform: "none" }}
              >
                Privacy & Policy
              </Typography>
            </Link>
          </Grid>
        </Grid>
      </Box>

      <Divider />
      <Box
        sx={{
          display: "flex",
          flexDirection: { xs: "column", sm: "row" },
          justifyContent: "space-between",
          alignItems: "center",
          color: "#fff",
          marginTop: 2,
          gap: 1,
        }}
      >
        <Box
          color="#fff"
          whiteSpace="nowrap"
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            gap: 0.5,
          }}
        >
          <Typography variant="body2">Copyright</Typography>
          <Copyright fontSize="inherit" />
          <Typography variant="body2">{new Date().getFullYear()}</Typography>
          <Typography variant="body2">Chatsbox.</Typography>
          <Typography variant="body2">All rights reserved.</Typography>
        </Box>

        <Box>
          <Typography variant="body1" sx={{ display: "inline" }}>
            Design And Developed by
          </Typography>
          <Link href="/" underline="hover" target="_blank" rel="noopener">
            <Typography
              variant="body1"
              sx={{
                display: "inline",
                marginX: 1,
                color: theme.palette.text.info,
                textTransform: "none",
              }}
            >
              Bloom It Solutions
            </Typography>{" "}
          </Link>
        </Box>
      </Box>
    </Box>
  );
}
