import { lazy } from "react";
import { v4 as uuidv } from "uuid";
import { useSelector } from "react-redux";
import { Route, Routes } from "react-router-dom";

import { appRoutes } from "./routes/config";

import { useScrollToTop } from "./hooks/use-scroll-to-top";

import HomeLayout from "./layouts/home";
import DashboardLayout from "./layouts/dashboard";

import ProtectedRoute from "./routes/ProtectedRoute";
import UnProtectedRoute from "./routes/UnProtectedRoute";
const RegisterPage = lazy(() => import("./pages/Register"));
const LoginPage = lazy(() => import("./pages/login"));
const Page404 = lazy(() => import("./pages/page-not-found"));
// home
const Home = lazy(() => import("./layouts/home/Home"));
const Pricing = lazy(() => import("./layouts/home/Pricing"));
const About = lazy(() => import("./layouts/home/About"));
const Contact = lazy(() => import("./layouts/home/Contact"));
const PrivacyPolicy = lazy(() => import("./pages/PrivacyPolicy"));
const TermsConditions = lazy(() => import("./pages/TermsConditions"));
const Profile = lazy(() => import("./pages/Profile"));
const Features = lazy(() => import("./layouts/home/Features"));
const Blog = lazy(() => import("./layouts/home/Blog"));
// --------------------------------------------------------

export default function App() {
  useScrollToTop();
  const { userInfo } = useSelector((state) => state.auth);

  return (
    <Routes>
      <Route path="/" element={<HomeLayout />}>
        <Route index element={<Home />} />
        <Route path="pricing" element={<Pricing />} />
        <Route path="about" element={<About />} />
        <Route path="contact" element={<Contact />} />
        <Route path="features" element={<Features />} />
        <Route path="blog" element={<Blog />} />
        <Route path="policy" element={<PrivacyPolicy />} />
        <Route path="terms" element={<TermsConditions />} />
      </Route>
      <Route path="/login" element={<LoginPage />} />
      <Route
        path="/register"
        element={
          <UnProtectedRoute>
            <RegisterPage />
          </UnProtectedRoute>
        }
      />

      <Route
        path="/app/"
        element={
          <ProtectedRoute>
            <DashboardLayout />
          </ProtectedRoute>
        }
      >
        {userInfo !== null
          ? appRoutes?.map((route) => (
              <Route
                key={uuidv()}
                path={route.path}
                element={<ProtectedRoute>{route.element}</ProtectedRoute>}
              />
            ))
          : null}
        <Route path="accounts/:tabTitle" element={<Profile />} />
      </Route>
      <Route path="*" element={<Page404 />} />
    </Routes>
  );
}
