import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import RestApi from "../api/RestApi";

// Async Thunk for sending an email
export const sendEmail = createAsyncThunk(
  "emails/sendEmail",
  async (emailData, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().auth;
      const response = await RestApi.post("/send-mail", emailData, {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

// Async Thunk for listing emails
export const listEmails = createAsyncThunk(
  "emails/listEmails",
  async (_, { rejectWithValue, getState }) => {
    try {
      const { userInfo } = getState().auth;
      const response = await RestApi.get("/list-mail", {
        headers: {
          Authorization: `Bearer ${userInfo.token}`,
        },
      });
      return response.data;
    } catch (error) {
      if (error.response && error.response.data.message) {
        return rejectWithValue(error.response.data.message);
      }
      return rejectWithValue(error.message);
    }
  }
);

const emailSlice = createSlice({
  name: "emails",
  initialState: {
    mailList: null,
    status: "idle",
    error: null,
    success: null,
  },
  reducers: {
    clearMessages: (state) => {
      state.success = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      // Send Email
      .addCase(sendEmail.pending, (state) => {
        state.status = "loading";
      })
      .addCase(sendEmail.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.success = action.payload.message;
      })
      .addCase(sendEmail.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      // List Emails
      .addCase(listEmails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(listEmails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.mailList = action.payload;
      })
      .addCase(listEmails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export const { clearMessages } = emailSlice.actions;

export default emailSlice.reducer;
