import {
  AutoAwesomeMotion,
  EmailOutlined,
  Home,
  Leaderboard,
  Person,
  Settings,
} from "@mui/icons-material";
import { lazy } from "react";

const IndexPage = lazy(() => import("../pages/app"));
// const UserPage = lazy(() => import("../pages/user"));
const Email = lazy(() => import("../pages/Email"));
const Leads = lazy(() => import("../pages/Leads"));
const Automation = lazy(() => import("../pages/Automation"));
const Profile = lazy(() => import("../pages/Profile"));
const Setting = lazy(() => import("../pages/Setting"));
export const appRoutes = [
  {
    group: "Dashboard",
    title: "Dashboard",
    path: "dashboard",
    element: <IndexPage />,
    isChild: false,
    icon: <Home />,
  },
  {
    group: "Dashboard",
    title: "Email",
    path: "email",
    element: <Email />,
    isChild: true,
    icon: <EmailOutlined />,
  },
  {
    group: "Dashboard",
    title: "Leads",
    path: "leads",
    element: <Leads />,
    isChild: false,
    icon: <Leaderboard />,
  },
  {
    group: "Dashboard",
    title: "Automation",
    path: "automation",
    element: <Automation />,
    isChild: false,
    icon: <AutoAwesomeMotion />,
  },
  {
    group: "Dashboard",
    title: "Accounts",
    path: "accounts",
    element: <Profile />,
    isChild: false,
    icon: <Person />,
  },
  {
    group: "Dashboard",
    title: "Settings",
    path: "setting",
    element: <Setting />,
    isChild: false,
    icon: <Settings />,
  },
];
